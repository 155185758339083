import { GraphQLClient } from 'graphql-request'
import { getClientHeaders } from '../../config'
import Campaign from './campaign'
import Pair from './pair'
import Playlist from './playlist'
import Subscription from './subscription'
import Task from './task'
import User from './user'

export const Moises = (config: any): any => {
  const session = {
    authToken: config.authToken || process.env.MOISES_AUTH_TOKEN,
    apiEndpoint: config.apiEndpoint || 'https://api.moises.ai'
  }

  const graphQLClient = new GraphQLClient(`${session.apiEndpoint}/graphql`, {
    credentials: 'include',
    mode: 'cors'
  })

  function auth(authToken: string): void {
    session.authToken = authToken
  }

  async function graphQL({
    query,
    variables
  }: {
    query: string
    variables?: any
  }): Promise<any> {
    if (!session.authToken) {
      throw new Error('You need to set your apiKey')
    }

    return graphQLClient.request(query, variables, {
      Authorization: session.authToken,
      ...getClientHeaders()
    })
  }

  const { toggleAutoRenewSubscription } = Subscription(graphQL)

  const { authorizeApp } = Pair(graphQL)

  const {
    uploadUrl,
    uploadFile,
    createTask,
    updateTrack,
    reprocessOperation,
    addOperationsToTask,
    fetchTasks,
    signTempComposerJwt,
    signOutTempComposerJwt
  } = Task(graphQL)

  const {
    track,
    createPlaylist,
    updatePlaylist,
    deletePlaylist,
    addTrackToPlaylist,
    removeTrackFromPlaylist,
    reorderTracksOnPlaylist
  } = Playlist(graphQL)

  const { getGlobalCampaign, getCampaignIndividual } = Campaign(graphQL)

  const { acceptTerms } = User(graphQL)

  return {
    acceptTerms,
    session,
    uploadUrl,
    uploadFile,
    createTask,
    updateTrack,
    getGlobalCampaign,
    getCampaignIndividual,
    reprocessOperation,
    addOperationsToTask,
    signTempComposerJwt,
    signOutTempComposerJwt,
    fetchTasks,
    track,
    createPlaylist,
    updatePlaylist,
    deletePlaylist,
    addTrackToPlaylist,
    removeTrackFromPlaylist,
    reorderTracksOnPlaylist,
    toggleAutoRenewSubscription,
    authorizeApp,
    auth,
    graphQL
  }
}
