import axios from 'axios'
import { gql } from 'graphql-request'

interface TaskCli {
  uploadUrl(data: { url: any }): Promise<any>
  uploadFile(data: { file: string }): Promise<any>
  createTask(data: { taskFile: any; operations: any }): Promise<any>
  updateTrack(data: { trackId: string; name: string }): Promise<any>
  reprocessOperation(data: {
    id: string
    supportedOperation: {
      name: string
      params: any
    }
  }): Promise<any>
  addOperationsToTask(data: { trackId: any; operations: any }): Promise<any>
  fetchTasks(i: { offset: number; limit: number }): Promise<any>
  signTempComposerJwt(): Promise<any>
  signOutTempComposerJwt(): Promise<any>
}

const Task = (graphQL: any): TaskCli => {
  const uploadFile = async ({ file }: { file: any }): Promise<any> => {
    const query = gql`
            mutation {
                uploadFile(input:"${file.name}", type:FILESYSTEM ){
                    tempLocation
                    signedUrl
                    name
                    input
                }
            }
        `

    const result = await graphQL({ query })

    await axios({
      url: result.uploadFile.signedUrl,
      method: 'PUT',
      // headers: { 'Content-Type': file.type },
      data: file
    })

    delete result.uploadFile.signedUrl

    result.uploadFile.provider = 'FILESYSTEM'
    return result.uploadFile
  }

  const uploadUrl = async ({ url }: { url: string }): Promise<any> => {
    const query = gql`
            mutation {
                uploadFile(input:"${url}", type:URL){
                    tempLocation
                    name
                    input
                }
            }
        `

    const result = await graphQL({ query })

    result.uploadFile.provider = 'URL'
    return result.uploadFile
  }

  const createTask = async ({
    taskFile,
    operations
  }: {
    taskFile: any
    operations: any
  }): Promise<any> => {
    const opsGql = operations.map((op: any) => {
      return `{ name: ${op.name} params: ${JSON.stringify(op.params)}}`
    })

    const mutation = gql`
            mutation {
                createTask(
                    file: {
                    provider: ${taskFile.provider}
                    tempLocation: "${taskFile.tempLocation}"
                    name: "${taskFile.name}"
                    input: "${taskFile.input}"
                    }
                    operations: [${opsGql}]
                )
            }
        `

    const unquotedQuery = mutation.replace(/"([^"]+)":/g, '$1:')

    const result = await graphQL({ query: unquotedQuery })

    return result.createTask
  }

  const updateTrack = async ({
    trackId,
    name
  }: {
    trackId: string
    name: string
  }): Promise<any> => {
    const variables = {
      id: trackId,
      data: {
        name
      }
    }
    const query = gql`
      mutation updateTrack($id: ID!, $data: UpdateTrackInput!) {
        updateTrack(id: $id, data: $data) {
          id
          name
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const reprocessOperation = async ({
    id,
    supportedOperation: { name, params }
  }: {
    id: string
    supportedOperation: {
      name: string
      params: any
    }
  }): Promise<any> => {
    const variables = {
      id,
      supportedOperation: { name, params: params ?? {} }
    }
    const query = gql`
      mutation ($id: ID!, $supportedOperation: OperationInput!) {
        reprocessOutdatedOperation(
          id: $id
          supportedOperation: $supportedOperation
        )
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const addOperationsToTask = async ({
    trackId,
    operations
  }: {
    trackId: string
    operations: any
  }): Promise<any> => {
    const variables = {
      trackId,
      operations
    }
    const query = gql`
      mutation ($trackId: ID!, $operations: [OperationInput!]!) {
        addOperationsToTrack(trackId: $trackId, operations: $operations)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const fetchTasks = async ({
    offset,
    limit,
    searchQuery = '',
    operationName
  }: {
    offset: number
    limit: number
    searchQuery: string
    operationName?: string
  }): Promise<any> => {
    const variables = {
      offset,
      limit,
      searchQuery,
      operationName
    }
    const query = gql`
      query {
        tracks(pagination: { offset: ${offset}, limit: ${limit} }, filters: { searchQuery: "${searchQuery}" }) {
          totalCount
          edges {
            node {
              id
              isDemo
              file {
                name
                input
              }
              operations {
                id
                name
                status
                createdAt
                startedAt
                completedAt
                params
                result
              }
              playlists {
                id
              }
            }
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const signTempComposerJwt = async (): Promise<any> => {
    const query = gql`
      mutation {
        signTempComposerJwt
      }
    `
    const result = await graphQL({ query, variables: {} })
    return result
  }

  const signOutTempComposerJwt = async (): Promise<any> => {
    const query = gql`
      mutation {
        deleteTempComposerJwt
      }
    `
    const result = await graphQL({ query, variables: {} })
    return result
  }

  return {
    uploadUrl,
    uploadFile,
    createTask,
    updateTrack,
    reprocessOperation,
    addOperationsToTask,
    signTempComposerJwt,
    signOutTempComposerJwt,
    fetchTasks
  }
}

export default Task
