import Head from 'next/head'
import NextNprogress from 'nextjs-progressbar'
import { ReactElement } from 'react'
import { Scripts } from './scripts'
import { RootContainerType } from './types'

export const RootContainer: React.FC<RootContainerType> = ({
  children
}): ReactElement => (
  <>
    <Head>
      <link rel="icon" href="/favicon-32x32.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#000000" />
      <meta name="robots" content="noindex, nofollow" />
      <title>Luthier</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=no"
      />
    </Head>
    <Scripts />
    <NextNprogress
      options={{ easing: 'ease', height: 1, showSpinner: false, speed: 500 }}
    />
    <div id="moises-app">{children}</div>
  </>
)
