import { request } from 'graphql-request'
import { useCallback, useEffect, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { config } from '../../../config'
import { UserToken } from '../../../context/types'
import { User, UserContext } from '../../../types'
import { useUserLogout } from '../user-logout'
import { getUserQuery } from './get-user-query'

export interface UseToast {
  user: UserContext
  set(user: UserContext): void
}

interface UseGetUserData {
  userToken: UserToken
}

const getUserData = async (userToken: string | null): Promise<User | null> => {
  if (!userToken) {
    return null
  }

  const { user } = await request(config.graphql.endpoint, getUserQuery, null, {
    authorization: userToken
  })

  return user as User
}

export const useGetUserData = ({ userToken }: UseGetUserData): UserContext => {
  const { logout } = useUserLogout()

  const [silentRetrying, setSilentRetrying] = useState(false)

  const { retry, loading, value, error } = useAsyncRetry(async () => {
    const userData: null | User = await getUserData(userToken)

    if (userToken && userData && !userData.claims?.includes('admin')) {
      logout()
      return null
    }

    return userData
  }, [userToken, logout])

  const silentRetry = useCallback(() => {
    setSilentRetrying(true)
    retry()
  }, [retry])

  useEffect(() => {
    // reset "silentRetrying" state after fetching the data
    if (loading === false && userToken) {
      setSilentRetrying(false)
    }
  }, [loading, userToken])

  return {
    value: value || null,
    error: !!error,
    retry,
    silentRetry,
    // bypass default loading state if "silentRetrying" is true
    loading: silentRetrying ? false : loading || !userToken
  }
}
