import { path } from 'ramda'
import pkg from '../../package.json'
import { ensure } from '../lib/ts'
import { dev } from './dev'
import { production } from './production'
import { stage } from './stage'
import { ClientHeaders, Config, Configs } from './types'

const configs: Configs = {
  dev,
  stage,
  production
}

type Env = 'production' | 'stage'

let env: Env = 'production'

if (global.window && global.window.location) {
  if (
    global.window.location.href.includes('luthier-stage.moises.ai') ||
    global.window.location.href.includes('dev.moises.ai')
  ) {
    env = 'stage'
  }
}

export const config: Config = ensure(path([env], configs))

export const getEnv = (): Env => env

export const getClientHeaders = (): ClientHeaders => ({
  'apollographql-client-name': 'ai.moises-studio-web',
  'apollographql-client-version': pkg.version
})
