import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { useTimeoutFn } from 'react-use'
import { Context } from '../../../context'

export const useRedirectToLoginOnAuthenticationTimeout = (): boolean => {
  const { push, pathname } = useRouter()
  const { userToken } = useContext(Context)

  const [isTokenTimeoutReady, cancelTokenTimeout] = useTimeoutFn(() => {
    if (!userToken && !['/login', '/not-found'].includes(pathname)) {
      push('/login')
    }
  }, 3000)

  useEffect(() => {
    const isTokenTimeoutPending = isTokenTimeoutReady() === false
    if (userToken && isTokenTimeoutPending) {
      cancelTokenTimeout()
    }
  }, [cancelTokenTimeout, isTokenTimeoutReady, userToken])

  return !!userToken
}
