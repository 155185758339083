import { gql } from 'graphql-request'

interface UserCli {
  acceptTerms(): Promise<any>
}

const User = (graphQL: any): UserCli => {
  const acceptTerms = async (): Promise<any> => {
    const query = gql`
      mutation {
        acceptTerms
      }
    `

    const result = await graphQL({ query })

    return result.acceptTerms
  }

  return {
    acceptTerms
  }
}

export default User
