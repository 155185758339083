import { useCallback, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { UserToken } from '../../../context/types'
import { FirebaseAuth, moisesSDK } from '../../../lib/firebase'

const delayRefreshOnRecentlyCreatedAccount = async (
  userPayload: any
): Promise<void> => {
  if (!userPayload) return

  const isOnlyPassword =
    userPayload.providerData.length === 1 &&
    userPayload.providerData.find(
      ({ providerId }: { providerId: string }) => providerId === 'password'
    )

  const accountRecentlyCreated = Date.now() - userPayload.createdAt < 5000

  if (isOnlyPassword && accountRecentlyCreated) {
    // eslint-disable-next-line no-new
    await new Promise((resolve) => setTimeout(resolve, 3000))
  }
}

export const useFirebaseToken = (): UserToken => {
  const [userToken, setUserToken] = useState<UserToken>(null)
  FirebaseAuth.useDeviceLanguage()

  const refreshFirebaseToken = useCallback(async () => {
    const { currentUser } = FirebaseAuth

    await delayRefreshOnRecentlyCreatedAccount(currentUser?.toJSON())

    const newToken = (await currentUser?.getIdToken()) || null
    if (newToken !== userToken) {
      if (newToken) {
        moisesSDK.auth.updateFirebaseToken(newToken)
      }

      setUserToken(newToken)
    }
  }, [userToken])

  const handleOnActive = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('activity registered after 3 min idle: refreshing fb token')
    refreshFirebaseToken()
  }, [refreshFirebaseToken])

  useIdleTimer({
    timeout: 1000 * 60 * 3, // trigger on activity after 3 minutes idle
    onActive: handleOnActive,
    debounce: 500
  })

  useEffect(() => {
    const unsubscribe = FirebaseAuth.onIdTokenChanged(async (user) => {
      if (user) {
        refreshFirebaseToken()
      } else {
        global.window.rudderstackUserLoaded = false
        setUserToken(null)
      }
    })

    return () => unsubscribe()
  }, [refreshFirebaseToken])

  return userToken
}
