import { useRouter } from 'next/router'
import { useCallback, useContext } from 'react'
import { config } from '../../../config'
import { Context } from '../../../context'
import { Moises as MoisesNew } from '../../../lib/cli'
import { useAuthentication } from '../../authentication'

interface UseUserLogout {
  logout(): void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useUserLogout = (): UseUserLogout => {
  const router = useRouter()
  const { signOut } = useAuthentication()
  const { userToken } = useContext(Context)

  const signOutTempComposerJwt = useCallback(async () => {
    if (userToken) {
      MoisesCLI.auth(userToken)
      await MoisesCLI.signOutTempComposerJwt()
    }
  }, [userToken])

  const logout = useCallback(async () => {
    if (!router.isReady) return

    await signOutTempComposerJwt()
    await signOut()

    const query: { [key: string]: any } = {}
    const { redirect } = router.query
    const { email } = router.query

    if (redirect) {
      query.redirect = redirect
    }

    if (email) {
      query.email = email
    }

    router.push({
      pathname: '/login',
      query
    })
  }, [router, signOut, signOutTempComposerJwt])

  return {
    logout
  }
}
