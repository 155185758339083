import React, { useContext } from 'react'
import { Error } from '../../components/error'
import { Context } from '../../context'
import { useRedirectToLoginOnAuthenticationTimeout } from '../../hooks/authentication'
import { AuthenticatedContainerType } from './types'

export const AppFrameWrapper: React.FC<AuthenticatedContainerType> = ({
  children
}) => {
  useRedirectToLoginOnAuthenticationTimeout()
  const { user } = useContext(Context)

  return (
    <div>
      {!user?.loading && user?.error && (
        <Error
          retry={user.retry}
          title="Oops..."
          description="Sorry, we couldn't load your information."
        />
      )}
      {!user?.error && children}
    </div>
  )
}
