import { AppProps } from 'next/app'
import React, { ReactElement } from 'react'
import { AuthenticatedContainer } from '../containers/authenticated-container'
import { RootContainer } from '../containers/root-container'
import { ToastContainer } from '../containers/toast-container'
import { ContextProvider } from '../context'
import '../styles/styles.scss'

const MyApp = ({ Component, pageProps }: AppProps): ReactElement => {
  return (
    <ContextProvider>
      <RootContainer>
        <AuthenticatedContainer>
          <>
            <ToastContainer />
            <Component {...pageProps} />
          </>
        </AuthenticatedContainer>
      </RootContainer>
    </ContextProvider>
  )
}

export default MyApp
