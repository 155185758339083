import { Config } from './types'

export const production: Config = {
  env: 'production',
  sentry: {
    dsn: 'https://d36a53b3df76452cb28b46bf166e1d94@o418792.ingest.sentry.io/4504243412140032'
  },
  getAudioMix: {
    endpoint: 'https://service-mixer-6fvc7ord4a-wl.a.run.app/v1'
  },
  urlDownload: {
    endpoint: 'https://url-download.moises.ai'
  },
  graphql: {
    endpoint: 'https://api.moises.ai/graphql'
  },
  api: {
    endpoint: 'https://api.moises.ai'
  },
  transcode: {
    endpoint: 'https://service-transcode-6fvc7ord4a-wl.a.run.app'
  },
  waves: {
    endpoint: 'https://waves-generator-v2-6fvc7ord4a-wl.a.run.app/waves'
  },
  serviceBillingApi: {
    endpoint: 'https://billing.moises.ai'
  },
  firebase: {
    auth: {
      apiKey: 'AIzaSyDWcFRZcUnN5EPNNA7jrcuS3HlIvMqtuCs',
      authDomain: 'app.moises.ai',
      databaseURL: 'https://spleeter.firebaseio.com',
      projectId: 'spleeter',
      storageBucket: 'spleeter.appspot.com',
      messagingSenderId: '731360694588',
      appId: '1:731360694588:web:4da0f700a5193a834ace39',
      measurementId: 'G-VCCHPVZC99'
    }
  },
  stripe: {
    apiKey: 'pk_live_V8LD1ZGLLGFjoryvB60ftb5X00Dkg8tkQj'
  },
  paypal: {
    clientId:
      'AUChJ6ogk_GxSl5advDUTX0OqAVQ6IcMeCJo3qcfmXODok1Pi-peOqCcejZwwP-d3aOlDzoU9diB1eV4'
  },
  composer: {
    endpoint: 'https://composer.moises.ai'
  },
  voicestudio: {
    endpoint: 'https://voice-studio.moises.ai'
  }
}
