import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { useRedirectToLoginOnAuthenticationTimeout } from '../../hooks/authentication'
import { AppFrameWrapper } from './app-frame-wrapper'
import { AuthenticatedContainerType } from './types'

export const routesToNotShowAppFrame = ['/', '/login', '/logout']

const getRoutes = (isUserLoogedIn: boolean): string[] =>
  routesToNotShowAppFrame.filter(() => {
    if (!isUserLoogedIn) return true

    return true
  })

export const AuthenticatedContainer = ({
  children
}: AuthenticatedContainerType): ReactElement => {
  const isUserLoogedIn = useRedirectToLoginOnAuthenticationTimeout()
  const { pathname } = useRouter()

  const routes = getRoutes(isUserLoogedIn)

  if (routes.includes(pathname)) {
    return children
  }

  return <AppFrameWrapper>{children}</AppFrameWrapper>
}
