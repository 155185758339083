import { FirebaseApp } from '../../../../lib/firebase'

const appleIdProvider = new FirebaseApp.auth.OAuthProvider('apple.com')
const googleProvider = new FirebaseApp.auth.GoogleAuthProvider()
const twitterProvider = new FirebaseApp.auth.TwitterAuthProvider()
const facebookProvider = new FirebaseApp.auth.FacebookAuthProvider()

appleIdProvider.addScope('email')
googleProvider.addScope('email')
facebookProvider.addScope('email')

export { appleIdProvider, googleProvider, twitterProvider, facebookProvider }
