import { gql } from 'graphql-request'

export const getUserQuery = gql`
  {
    user {
      featureFlags
      id
      name
      email
      name
      email
      lastSignInAt
      createdAt
      emailVerified
      claims
      profilePictureUrl
      subscription {
        isPremium
        plan
        availableCredits
        currentMonthlyUsage
        details {
          pending
          autoRenew
          providerName
          planCycle
          expireDate
          providerGateway
        }
      }
      preferences {
        communication {
          activity {
            push
            email
          }
          updates {
            push
            email
          }
        }
      }
      goals
      instruments {
        skillLevel
        instrumentId
      }
      actionNeeded {
        hasTermsToAccept
      }
    }
  }
`
