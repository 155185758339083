import classnames from 'classnames'
import { useCallback } from 'react'
import styles from './styles.module.scss'

export interface AlertProps {
  id: string
  type: string
  closable: boolean
  fixed?: boolean
  title?: string | null
  description?: string | null
  buttonText?: string | null
  onConfirm?(id: string): void
  onDismiss?(id: string): void
}

export const Alert: React.FC<AlertProps> = ({
  id,
  type,
  title,
  closable,
  buttonText,
  description,
  onConfirm,
  onDismiss
}) => {
  const handleDismiss = useCallback(() => {
    if (onDismiss) {
      onDismiss(id)
    }
  }, [id, onDismiss])

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm(id)
    }
  }, [id, onConfirm])

  return (
    <div
      className={classnames(styles.alert, {
        [styles.default]: type === 'default',
        [styles.error]: type === 'error',
        [styles.warning]: type === 'warning',
        [styles.success]: type === 'success',
        [styles.aurora]: type === 'aurora',
        [styles.sunrise]: type === 'sunrise',
        [styles.sunset]: type === 'sunset',
        [styles.nightfall]: type === 'nightfall',
        [styles.spotlight]: type === 'spotlight'
      })}
    >
      <div className={styles.contentWrapper}>
        {title && <p className={styles.title}>{title}</p>}

        {description && (
          <p
            className={classnames(styles.description, {
              [styles.withTitle]: !!title
            })}
          >
            {description}
          </p>
        )}

        {onConfirm && buttonText && (
          <button
            type="button"
            onClick={handleConfirm}
            className="rounded-md border w-fit border-transparent bg-whiteBg-30 px-2 py-1 text-xs mt-2 font-medium leading-4 text-gray-700 shadow-sm hover:bg-whiteBg-60 focus:outline-none focus:ring-2 focus:bg-whiteBg-30 focus:ring-offset-2"
          >
            {buttonText}
          </button>
        )}
      </div>

      {closable && (
        <button type="button" onClick={handleDismiss} className={styles.close}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      )}
    </div>
  )
}
