import { gql } from 'graphql-request'

interface PlaylistCli {
  track(data: { id: string }): Promise<any>
  createPlaylist(name: string): Promise<any>
  deletePlaylist(data: { playlistId: string }): Promise<any>
  addTrackToPlaylist(data: {
    playlistId: string
    trackId: string
  }): Promise<any>
  removeTrackFromPlaylist(data: { trackEdgeId: string }): Promise<any>
  reorderTracksOnPlaylist(data: {
    playlistId: string
    order: number
    moveBefore: number
  }): Promise<any>
  updatePlaylist(data: {
    playlistId: string
    name: string
    description: string | null
  }): Promise<any>
}

const Playlist = (graphQL: any): PlaylistCli => {
  const createPlaylist = async (name: string): Promise<any> => {
    const variables = {
      name
    }
    const query = gql`
      mutation createPlaylist($name: String, $description: String) {
        createPlaylist(name: $name, description: $description) {
          id
          name
          description
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result.createPlaylist
  }

  const addTrackToPlaylist = async ({
    playlistId,
    trackId
  }: {
    playlistId: string
    trackId: string
  }): Promise<any> => {
    const variables = {
      playlistId,
      trackId
    }
    const query = gql`
      mutation addTrackToPlaylist($playlistId: ID!, $trackId: ID!) {
        addTrackToPlaylist(playlistId: $playlistId, trackId: $trackId) {
          id
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const removeTrackFromPlaylist = async ({
    trackEdgeId
  }: {
    trackEdgeId: string
  }): Promise<any> => {
    const variables = {
      trackEdgeId
    }
    const query = gql`
      mutation removeTrackFromPlaylist($trackEdgeId: ID!) {
        removeTrackFromPlaylist(trackEdgeId: $trackEdgeId)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const deletePlaylist = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = {
      id: playlistId
    }
    const query = gql`
      mutation deletePlaylist($id: ID!) {
        deletePlaylist(id: $id)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updatePlaylist = async ({
    playlistId,
    name,
    description
  }: {
    playlistId: string
    name: string
    description: string | null
  }): Promise<any> => {
    const variables = {
      id: playlistId,
      data: {
        name,
        description
      }
    }
    const query = gql`
      mutation updatePlaylist($id: ID!, $data: UpdatePlaylistInput!) {
        updatePlaylist(id: $id, data: $data) {
          id
          name
          description
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const reorderTracksOnPlaylist = async ({
    playlistId,
    order,
    moveBefore
  }: {
    playlistId: string
    order: number
    moveBefore: number
  }): Promise<any> => {
    const variables = {
      playlistId,
      reorder: [{ order, move_before: moveBefore }]
    }
    const query = gql`
      mutation reorderTracksOnPlaylist(
        $playlistId: ID!
        $reorder: [TrackReorderInput!]!
      ) {
        reorderTracksOnPlaylist(playlistId: $playlistId, reorder: $reorder)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const track = async ({ id }: { id: string }): Promise<any> => {
    const variables = {
      id
    }
    const query = gql`
      query track($id: String!) {
        track(id: $id) {
          id
          file {
            name
            input
          }
          playlists {
            id
          }
          operations {
            id
            name
            status
            createdAt
            startedAt
            completedAt
            params
            result
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    track,
    createPlaylist,
    updatePlaylist,
    deletePlaylist,
    addTrackToPlaylist,
    removeTrackFromPlaylist,
    reorderTracksOnPlaylist
  }
}

export default Playlist
