import React from 'react'

export interface ErrorProps {
  title?: string
  description?: string
  retry?(): void
}

export const Error: React.FC<ErrorProps> = ({ title, description, retry }) => {
  return (
    <div className="h-full align-center mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        {title && (
          <p className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
            {title}
          </p>
        )}

        {description && (
          <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
            {description}
          </p>
        )}

        {retry && (
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-6"
          >
            Retry
          </button>
        )}
      </div>
    </div>
  )
}
