import React, { ReactElement } from 'react'
import { useFirebaseToken } from '../hooks/authentication'
import { useToast } from '../hooks/toast'
import { useGetUserData } from '../hooks/user/use-get-user-data'
import { AppContext } from './types'

export const Context = React.createContext<AppContext>({
  userToken: null,
  user: null,
  toast: {
    list: [],
    add: () => {},
    onClearFixedToast: () => {}
  }
})

export const ContextProvider = ({
  children
}: {
  children: React.ReactNode
}): ReactElement => {
  const toast = useToast()
  const userToken = useFirebaseToken()
  const user = useGetUserData({ userToken })

  return (
    <Context.Provider
      value={{
        toast,
        user,
        userToken
      }}
    >
      {children}
    </Context.Provider>
  )
}
